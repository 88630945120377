// Import the functions you need from the SDKs
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"; // Import Firebase Authentication
//import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCNQfdg4Cuig1gWe-bfp8rBCDTbigNaQg4",
  authDomain: "paymentzero-14dd4.firebaseapp.com",
  projectId: "paymentzero-14dd4",
  storageBucket: "paymentzero-14dd4.firebasestorage.app",
  messagingSenderId: "224717553078",
  appId: "1:224717553078:web:060402b7ba216214781517",
  measurementId: "G-DR6XME908L"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics (optional, use only if needed)
//const analytics = getAnalytics(app);

// Initialize and export Firebase Authentication
export const auth = getAuth(app);
export const db = getFirestore(app);