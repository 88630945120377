import React, { useState, useEffect } from 'react';
import { auth, db } from '../config/firebase';
import { collection, addDoc, query, where, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';

const UserHistory = () => {
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingId, setEditingId] = useState(null);
  const [editedNote, setEditedNote] = useState('');

  useEffect(() => {
    fetchHistory();
  }, []);

  const fetchHistory = async () => {
    try {
      const historyRef = collection(db, 'history');
      const q = query(historyRef, where('userId', '==', auth.currentUser.uid));
      const querySnapshot = await getDocs(q);
      
      const historyData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate()
      }));
      
      setHistory(historyData.sort((a, b) => b.timestamp - a.timestamp));
      setLoading(false);
    } catch (error) {
      console.error('Error fetching history:', error);
      setLoading(false);
    }
  };

  const saveToHistory = async (content, type, title) => {
    try {
      await addDoc(collection(db, 'history'), {
        userId: auth.currentUser.uid,
        content,
        type,
        title,
        timestamp: new Date(),
        isBookmarked: false,
        note: ''
      });
      fetchHistory();
    } catch (error) {
      console.error('Error saving to history:', error);
    }
  };

  const toggleBookmark = async (id, currentBookmarkStatus) => {
    try {
      const docRef = doc(db, 'history', id);
      await updateDoc(docRef, {
        isBookmarked: !currentBookmarkStatus
      });
      fetchHistory();
    } catch (error) {
      console.error('Error toggling bookmark:', error);
    }
  };

  const deleteItem = async (id) => {
    try {
      await deleteDoc(doc(db, 'history', id));
      fetchHistory();
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const startEditing = (id, note) => {
    setEditingId(id);
    setEditedNote(note);
  };

  const saveNote = async (id) => {
    try {
      const docRef = doc(db, 'history', id);
      await updateDoc(docRef, {
        note: editedNote
      });
      setEditingId(null);
      fetchHistory();
    } catch (error) {
      console.error('Error saving note:', error);
    }
  };

  const formatTimestamp = (date) => {
    const now = new Date();
    const diff = now - date;
    const minutes = Math.floor(diff / 60000);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (minutes < 60) {
      return `${minutes} minutes ago`;
    } else if (hours < 24) {
      return `${hours} hours ago`;
    } else {
      return `${days} days ago`;
    }
  };

  const styles = {
    container: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '20px'
    },
    card: {
      backgroundColor: 'white',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      padding: '24px'
    },
    title: {
      fontSize: '24px',
      fontWeight: 'bold',
      marginBottom: '24px'
    },
    loading: {
      textAlign: 'center',
      padding: '16px',
      color: '#666'
    },
    historyItem: {
      border: '1px solid #e5e7eb',
      borderRadius: '8px',
      padding: '16px',
      marginBottom: '16px',
      transition: 'box-shadow 0.3s ease'
    },
    itemHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginBottom: '8px'
    },
    itemTitle: {
      fontSize: '18px',
      fontWeight: '600',
      marginBottom: '4px'
    },
    timestamp: {
      fontSize: '14px',
      color: '#6b7280'
    },
    buttonGroup: {
      display: 'flex',
      gap: '8px'
    },
    iconButton: {
      padding: '8px',
      border: 'none',
      background: 'none',
      cursor: 'pointer',
      borderRadius: '4px',
      transition: 'background-color 0.2s'
    },
    content: {
      marginTop: '8px',
      color: '#374151'
    },
    noteSection: {
      marginTop: '16px'
    },
    textarea: {
      width: '100%',
      padding: '8px',
      borderRadius: '4px',
      border: '1px solid #d1d5db',
      marginBottom: '8px',
      minHeight: '80px'
    },
    saveButton: {
      backgroundColor: '#3b82f6',
      color: 'white',
      padding: '8px 16px',
      borderRadius: '4px',
      border: 'none',
      cursor: 'pointer',
      transition: 'background-color 0.2s'
    },
    noteDisplay: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      color: '#6b7280',
      fontStyle: 'italic'
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <h2 style={styles.title}>Your Learning History</h2>
        
        {loading ? (
          <div style={styles.loading}>Loading...</div>
        ) : history.length === 0 ? (
          <div style={styles.loading}>No history found</div>
        ) : (
          history.map((item) => (
            <div 
              key={item.id} 
              style={styles.historyItem}
              onMouseOver={(e) => e.currentTarget.style.boxShadow = '0 4px 6px rgba(0,0,0,0.1)'}
              onMouseOut={(e) => e.currentTarget.style.boxShadow = 'none'}
            >
              <div style={styles.itemHeader}>
                <div>
                  <h3 style={styles.itemTitle}>{item.title}</h3>
                  <p style={styles.timestamp}>
                    {formatTimestamp(item.timestamp)}
                  </p>
                </div>
                <div style={styles.buttonGroup}>
                  <button
                    onClick={() => toggleBookmark(item.id, item.isBookmarked)}
                    style={styles.iconButton}
                    title={item.isBookmarked ? "Unbookmark" : "Bookmark"}
                  >
                    {item.isBookmarked ? '★' : '☆'}
                  </button>
                  <button
                    onClick={() => deleteItem(item.id)}
                    style={styles.iconButton}
                    title="Delete"
                  >
                    ×
                  </button>
                </div>
              </div>

              <div style={styles.content}>{item.content}</div>

              <div style={styles.noteSection}>
                {editingId === item.id ? (
                  <div>
                    <textarea
                      value={editedNote}
                      onChange={(e) => setEditedNote(e.target.value)}
                      style={styles.textarea}
                      placeholder="Add a note..."
                    />
                    <button
                      onClick={() => saveNote(item.id)}
                      style={styles.saveButton}
                    >
                      Save Note
                    </button>
                  </div>
                ) : (
                  <div style={styles.noteDisplay}>
                    <span>{item.note || 'No notes'}</span>
                    <button
                      onClick={() => startEditing(item.id, item.note)}
                      style={styles.iconButton}
                      title="Edit Note"
                    >
                      ✎
                    </button>
                  </div>
                )}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default UserHistory;