import React, { useState } from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { auth, db } from '../config/firebase';
import { collection, addDoc } from 'firebase/firestore';

const API_URL = 'https://server-f2f.onrender.com/api';

const BuilderAI = () => {
  const [lessonTitle, setLessonTitle] = useState('');
  const [targetAge, setTargetAge] = useState('');
  const [subject, setSubject] = useState('');
  const [objectives, setObjectives] = useState('');
  const [generatedContent, setGeneratedContent] = useState('');
  const [editedContent, setEditedContent] = useState('');
  const [isLoading, setIsLoading] = useState(false);



  const saveToHistory = async (content, title) => {
    try {
      await addDoc(collection(db, 'history'), {
        userId: auth.currentUser.uid,
        content,
        type: 'lesson-plan',
        title,
        timestamp: new Date(),
        isBookmarked: false,
        note: ''
      });
    } catch (error) {
      console.error('Error saving to history:', error);
    }
  };
  const generateLesson = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API_URL}/generate-lesson`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          title: lessonTitle,
          targetAge,
          subject,
          objectives
        })
      });
      const data = await response.json();
      setGeneratedContent(data.lesson);
      setEditedContent(data.lesson);
      await saveToHistory(data.lesson, lessonTitle);
    } catch (error) {
      console.error('Error:', error);
    }
    setIsLoading(false);
  };

  const exportToPDF = async () => {
    const content = document.getElementById('lesson-content');
    const canvas = await html2canvas(content);
    const pdf = new jsPDF();
    
    pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
    pdf.save(`${lessonTitle}.pdf`);
  };

  return (
    <div className="app">
      <div className="card">
        <h1 className="card-title">BuilderAI - Lesson Creator</h1>
        
        <div className="grid">
          <div className="section">
            <input
              className="input"
              placeholder="Lesson Title"
              value={lessonTitle}
              onChange={(e) => setLessonTitle(e.target.value)}
            />
            <input
              className="input"
              placeholder="Target Age/Grade"
              value={targetAge}
              onChange={(e) => setTargetAge(e.target.value)}
            />
            <input
              className="input"
              placeholder="Subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
            <textarea
              className="textarea"
              placeholder="Learning Objectives (one per line)"
              value={objectives}
              onChange={(e) => setObjectives(e.target.value)}
            />
          </div>
        </div>

        <button 
          className="button"
          onClick={generateLesson}
          disabled={isLoading || !lessonTitle || !subject}
        >
          Generate Lesson Plan
        </button>

        {isLoading ? (
          <div className="loading">Generating your lesson plan...</div>
        ) : (
          generatedContent && (
            <div className="card" id="lesson-content">
              <div className="section">
                <h3 className="section-title">Edit Lesson Content</h3>
                <textarea
                  className="textarea"
                  value={editedContent}
                  onChange={(e) => setEditedContent(e.target.value)}
                  style={{ minHeight: '400px' }}
                />
              </div>
              <button className="button" onClick={exportToPDF}>
                Export to PDF
              </button>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default BuilderAI;