import React, { useState, useEffect } from "react";
import { auth } from "../config/firebase";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";

const firestore = getFirestore();

function Profile() {
  const [username, setUsername] = useState("");
  const [customUsername, setCustomUsername] = useState("");
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Retrieve authenticated user details
    const currentUser = auth.currentUser;
    if (currentUser) {
      setUser(currentUser);

      // Fetch custom username from Firestore
      const fetchUsername = async () => {
        const userDoc = doc(firestore, "users", currentUser.uid);
        const userSnapshot = await getDoc(userDoc);

        if (userSnapshot.exists()) {
          setCustomUsername(userSnapshot.data().username || "");
        }
      };
      fetchUsername();
    }
  }, []);

  const saveUsername = async () => {
    if (!username) {
      alert("Please enter a username.");
      return;
    }

    try {
      // Save custom username to Firestore
      const userDoc = doc(firestore, "users", user.uid);
      await setDoc(userDoc, { username });

      alert("Username saved successfully!");
      setCustomUsername(username); // Update local state
      setUsername(""); // Clear input field
    } catch (error) {
      console.error("Error saving username:", error);
      alert("Failed to save username. Please try again.");
    }
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <h1>Profile</h1>
      <p><strong>Name:</strong> {user.displayName || "N/A"}</p>
      <p><strong>Email:</strong> {user.email}</p>
      <p><strong>Custom Username:</strong> {customUsername || "No username set"}</p>

      <div>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Enter a custom username"
          style={{ padding: "10px", marginRight: "10px" }}
        />
        <button onClick={saveUsername} style={{ padding: "10px 20px" }}>
          Save Username
        </button>
      </div>
    </div>
  );
}

export default Profile;
