import React from "react";
import { Link, useLocation } from "react-router-dom";

const styles = {
  dashboard: {
    display: 'flex',
    minHeight: '100vh',
    backgroundColor: '#f8f9fa'
  },
  sidebar: {
    width: '250px',
    backgroundColor: 'white',
    padding: '20px',
    boxShadow: '2px 0 4px rgba(0,0,0,0.1)',
    position: 'fixed',
    height: '100vh'
  },
  content: {
    flex: 1,
    marginLeft: '250px',
    padding: '20px'
  },
  nav: {
    listStyle: 'none',
    padding: 0,
    margin: 0
  },
  navItem: {
    marginBottom: '10px'
  },
  navLink: {
    display: 'block',
    padding: '10px',
    color: '#333',
    textDecoration: 'none',
    borderRadius: '4px',
    transition: 'background-color 0.2s'
  },
  activeLink: {
    backgroundColor: '#007bff',
    color: 'white'
  }
};

const Dashboard = ({ children, userRole }) => {
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path ? styles.activeLink : {};
  };

  return (
    <div style={styles.dashboard}>
      <nav style={styles.sidebar}>
        <ul style={styles.nav}>
          <li style={styles.navItem}>
            <Link 
              to="/" 
              style={{ ...styles.navLink, ...isActive('/') }}
            >
              {userRole === 'student' ? 'AI Quiz' : 'Lesson Builder'}
            </Link>
          </li>
          <li style={styles.navItem}>
            <Link 
              to="/profile" 
              style={{ ...styles.navLink, ...isActive('/profile') }}
            >
              Profile
            </Link>
          </li>
          <li style={styles.navItem}>
            <Link 
              to="/history" 
              style={{ ...styles.navLink, ...isActive('/history') }}
            >
              Learning History
            </Link>
          </li>
        </ul>
      </nav>
      <main style={styles.content}>{children}</main>
    </div>
  );
};

export default Dashboard;