import React, { useState } from 'react';
import { auth, db } from '../config/firebase';
import { collection, addDoc } from 'firebase/firestore';

const styles = `
  .app {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }

  .card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding: 20px;
  }

  .card-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  }

  .button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
  }

  .button:hover {
    background-color: #0056b3;
  }

  .button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .button.selected {
    background-color: #28a745;
  }

  .button.outline {
    background-color: transparent;
    border: 1px solid #007bff;
    color: #007bff;
  }

  .button.outline:hover {
    background-color: #f8f9fa;
  }

  .mode-buttons {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-bottom: 20px;
  }

  .section {
    margin-bottom: 20px;
  }

  .section-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #444;
  }

  .button-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
    font-size: 14px;
  }

  .textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
    font-size: 14px;
    min-height: 100px;
    resize: vertical;
  }

  .loading {
    text-align: center;
    padding: 20px;
    color: #666;
  }

  .lesson-content {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 4px;
    line-height: 1.6;
  }

  .lesson-image {
    max-width: 100%;
    border-radius: 8px;
    margin-top: 20px;
  }
`;

const API_URL = 'https://server-f2f.onrender.com/api';

const subjects = [
  'Mathematics', 'Physics', 'Chemistry', 'Biology', 'History',
  'Literature', 'Computer Science', 'Art', 'Music', 'Philosophy'
];

export default function AIQuiz() {
  const [selectedSubject, setSelectedSubject] = useState('');
  const [subtopics, setSubtopics] = useState([]);
  const [selectedSubtopic, setSelectedSubtopic] = useState('');
  const [lesson, setLesson] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [customSubject, setCustomSubject] = useState('');
  const [mode, setMode] = useState('regular');
  const [kidsImage, setKidsImage] = useState('');



  const saveToHistory = async (content, title) => {
    try {
      await addDoc(collection(db, 'history'), {
        userId: auth.currentUser.uid,
        content,
        type: 'lesson',
        title,
        timestamp: new Date(),
        isBookmarked: false,
        note: ''
      });
    } catch (error) {
      console.error('Error saving to history:', error);
    }
  };
  const generateSubtopics = async (subject) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API_URL}/subtopics`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ subject })
      });
      const data = await response.json();
      setSubtopics(data.subtopics);
      await saveToHistory(data.lesson, 'lesson', selectedSubtopic);
    } catch (error) {
      console.error('Error:', error);
      setSubtopics([]);
    }
    setIsLoading(false);
  };

  const generateLesson = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API_URL}/lesson`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          subject: selectedSubject,
          subtopic: selectedSubtopic,
          mode
        })
      });
      const data = await response.json();
      setLesson(data.lesson.replace(/\./g, '.<br>'));
      if (data.image) {
        setKidsImage(data.image);
      }
      await saveToHistory(data.lesson, 'lesson', selectedSubtopic);
    } catch (error) {
      console.error('Error:', error);
      setLesson('');
    }
    setIsLoading(false);
  };

  const generateStory = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API_URL}/story`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
      });
      const data = await response.json();
      setLesson(data.story.replace(/\./g, '.<br>'));
      await saveToHistory(data.story, 'Generated Story');
    } catch (error) {
      console.error('Error:', error);
      setLesson('');
    }
    setIsLoading(false);
  };

  return (
    <>
      <style>{styles}</style>
      <div className="app">
        <div className="card">
          <h1 className="card-title">Focus2Flow</h1>
          
          <div className="mode-buttons">
            <button 
              className={`button ${mode === 'regular' ? 'selected' : 'outline'}`}
              onClick={() => setMode('regular')}
            >
              Regular Mode
            </button>
            <button 
              className={`button ${mode === 'kids' ? 'selected' : 'outline'}`}
              onClick={() => setMode('kids')}
            >
              Kids Mode
            </button>
            <button 
              className={`button ${mode === 'story' ? 'selected' : 'outline'}`}
              onClick={() => setMode('story')}
            >
              Story Mode
            </button>
          </div>

          {mode !== 'story' && (
            <div className="grid">
              <div className="section">
                <h3 className="section-title">Select Subject</h3>
                <div className="button-grid">
                  {subjects.map(subject => (
                    <button
                      key={subject}
                      className={`button ${selectedSubject === subject ? 'selected' : 'outline'}`}
                      onClick={() => {
                        setSelectedSubject(subject);
                        generateSubtopics(subject);
                      }}
                    >
                      {subject}
                    </button>
                  ))}
                </div>
              </div>

              <div className="section">
                <h3 className="section-title">Custom Subject</h3>
                <input
                  className="input"
                  placeholder="Enter subject name"
                  value={customSubject}
                  onChange={(e) => setCustomSubject(e.target.value)}
                />
                <button 
                  className="button"
                  onClick={() => {
                    setSelectedSubject(customSubject);
                    generateSubtopics(customSubject);
                  }}
                  disabled={!customSubject}
                >
                  Create Custom Subject
                </button>
              </div>
            </div>
          )}

          {subtopics.length > 0 && mode !== 'story' && (
            <div className="section">
              <h3 className="section-title">Select Subtopic</h3>
              <div className="button-grid">
                {subtopics.map((subtopic, index) => (
                  <button
                    key={index}
                    className={`button ${selectedSubtopic === subtopic ? 'selected' : 'outline'}`}
                    onClick={() => setSelectedSubtopic(subtopic)}
                  >
                    {subtopic}
                  </button>
                ))}
              </div>
            </div>
          )}

          {selectedSubtopic && mode !== 'story' && (
            <button 
              className="button"
              onClick={generateLesson}
              disabled={isLoading}
            >
              Generate {mode === 'kids' ? "Kids' " : ''}Lesson
            </button>
          )}

          {mode === 'story' && (
            <button 
              className="button"
              onClick={generateStory}
              disabled={isLoading}
            >
              Generate Story
            </button>
          )}

          {isLoading ? (
            <div className="loading">Loading...</div>
          ) : (
            lesson && (
              <div className="card">
                <div className="lesson-content">
                  <div dangerouslySetInnerHTML={{ __html: lesson }} />
                  {kidsImage && (
                    <img src={kidsImage} alt="Lesson illustration" className="lesson-image" />
                  )}
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
}