import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import AIQuiz from "./components/AIQuiz";
import BuilderAI from "./components/BuilderAI";
import Profile from "./components/Profile";
import UserHistory from './components/UserHistory';
import { signInWithPopup, GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";
import { auth } from "./config/firebase";
import "./App.css";

function App() {
  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser || null);
    });
    return () => unsubscribe();
  }, []);

  const SignUpWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
    } catch (error) {
      console.error("Error during sign-in:", error);
    }
  };

  if (!user) {
    return (
      <div className="SignInPage">
        <h1>Learn. Plan. Engage<br/></h1>
        <img src="../FOCUS.jpg" alt="Description"  
        style={{ width: '200px', height: '200px', borderRadius: '50%' }}
         />
         <br/>
        <button onClick={SignUpWithGoogle}>Sign in with Google</button>
      </div>
    );
  }

  if (!userRole) {
    return (
      <div className="RoleSelection">
        <h2>Select Your Role</h2>
        <div className="role-buttons">
          <button className="button" onClick={() => setUserRole('student')}>
            I am a Student
          </button>
          <button className="button" onClick={() => setUserRole('educator')}>
            I am an Educator
          </button>
        </div>
      </div>
    );
  }

  return (
    <Router>
      <Dashboard userRole={userRole}>
        <Routes>
          <Route 
            path="/" 
            element={userRole === 'student' ? <AIQuiz /> : <BuilderAI />} 
          />
          <Route path="/profile" element={<Profile />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/history" element={<UserHistory />} />
        </Routes>
      </Dashboard>
    </Router>
  );
}

export default App;